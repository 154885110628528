import React from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import * as Color from "chamber/lib/designs";
import styled from "@emotion/styled";
import { css } from "emotion";
import { useNotification } from "../../modules/notification/hooks";

const CloseBtn = styled.button`
  font-size: 16px;
  color: ${Color.White};
  background: ${Color.TextColor};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: none;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const SnackbarStyle = css`
  font-size: 12px;
  padding: 10px 10px 10px 20px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  color: ${Color.White};
  background: ${Color.TextColor} !important;
`;

export const SimpleSnackbar: React.FunctionComponent = () => {
  const { message, open, closeMessage } = useNotification();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      autoHideDuration={5000}
      open={open}
      onClose={() => closeMessage()}
    >
      <SnackbarContent
        className={SnackbarStyle}
        message={message}
        action={[
          <CloseBtn
            key="close"
            className="material-icons"
            onClick={() => closeMessage()}
          >
            close
          </CloseBtn>
        ]}
      />
    </Snackbar>
  );
};
