import React, { useEffect } from "react";
import { css } from "emotion";
import { RegistrationHeader } from "./RegistrationHeader";
import * as color from "chamber/lib/designs";
import { useRegistration } from "./hooks";
import { RegistrationTemporaryPasswordForm } from "./RegistrationTemporaryPasswordForm";
import { RegistrationNewPasswordForm } from "./RegistrationNewPasswordForm";
import { RegistrationCompleted } from "./RegistrationCompleted";
import { useLogin } from "../../hooks/useLogin";
import { NotLoggedInLayout } from "../../components/NotLoggedInLayout";

export const RegistrationForm: React.FunctionComponent = () => {
  const { isLoggedIn, redirect } = useLogin();

  const {
    step,
    temporaryPassword,
    email,
    validateTemporaryPassword,
    registrationRequest
  } = useRegistration();

  const redirectIfLoggedIn = async () => {
    if (await isLoggedIn()) {
      redirect();
    }
  };

  useEffect(() => {
    void redirectIfLoggedIn();
  });

  return (
    <NotLoggedInLayout>
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        `}
      >
        <main
          className={css`
            width: 600px;
            height: 340px;
            border-radius: 8px;
            background: ${color.White};
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
              0 2px 10px 0 rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            // border-radiusの表示のため
            overflow: hidden;
          `}
        >
          <RegistrationHeader step={step} />
          <div
            className={css`
              // 親がflex+columnでmaxの高さを取りたいため
              flex: 1;
            `}
          >
            {step === 1 && (
              <RegistrationTemporaryPasswordForm
                validateTemporaryPassword={validateTemporaryPassword}
              />
            )}
            {step === 2 && temporaryPassword && email && (
              <RegistrationNewPasswordForm
                temporaryPassword={temporaryPassword}
                email={email}
                registrationRequest={registrationRequest}
              />
            )}
            {step === 3 && <RegistrationCompleted />}
          </div>
        </main>
      </div>
    </NotLoggedInLayout>
  );
};
