import * as React from "react";
import { Image } from "semantic-ui-react";
import { css } from "emotion";
import { OuterLink } from "../../components/OuterLink";
import { JtxBlue, White } from "chamber/lib/designs";

export interface Props {
  title: string;
  icon: string;
  url: string;
  isOuterLink?: boolean;
  imageSize?: "small" | "large";
}

export class ServiceLink extends React.Component<Props> {
  imgSmall = css({
    "&.ui.image": {
      width: "32px",
      height: "32px"
    }
  });
  imgLarge = css({
    "&.ui.image": {
      width: "58px",
      height: "58px"
    }
  });

  render() {
    return (
      <div
        className={css({
          position: "relative",
          display: "flex",
          margin: "0.5em 0",
          height: "8em",
          borderRadius: "0.5em",
          flex: "0 0 auto"
        })}
      >
        <OuterLink
          className={css({
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "0.5em",
            zIndex: 1,
            transition: "all ease 0.1s",
            "&:hover": {
              boxShadow: "0 5px 5px 1px rgba(0, 0, 0, 0.7)",
              "&:active": {
                boxShadow: "none"
              }
            }
          })}
          to={this.props.url}
          target={this.props.isOuterLink ? "_blank" : "_parent"}
        />
        <div
          className={css({
            flexBasis: "100%",
            color: White,
            backgroundColor: JtxBlue,
            borderRadius: "0.5em",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer"
          })}
        >
          <div
            className={css({
              width: "70px",
              height: "70px",
              backgroundColor: White,
              borderRadius: "50%",
              margin: "1em",
              flexShrink: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            })}
          >
            <Image
              className={
                this.props.imageSize === "small" ? this.imgSmall : this.imgLarge
              }
              src={this.props.icon}
            />
          </div>
          {this.props.title}
        </div>
      </div>
    );
  }
}
