import axiosStatic, { AxiosInstance } from "axios";
import {
  PORTAL_PAGE_URL,
  SHANGHAI_API_ENDPOINT,
  SHANGHAI_API_KEY
} from "../constants";
import parseDomain from "parse-domain";

export const useLogin = (axios: AxiosInstance = axiosStatic) => {
  const redirect = (w: Window = window) => {
    const redirectTo = new URL(w.location.href).searchParams.get("redirect_to");
    // localhost は parseDomain で処理できないので自前で行う
    if (w.location.hostname === "localhost") {
      w.location.href = redirectTo || PORTAL_PAGE_URL;
      return;
    }

    // ログイン時のリダイレクトは常にホーム画面へと飛ぶ
    const parsedRedirectTo = parseDomain(redirectTo || "");
    const parsedLocationHref = parseDomain(w.location.href);
    if (
      redirectTo &&
      parsedRedirectTo &&
      parsedLocationHref &&
      parsedRedirectTo.domain === parsedLocationHref.domain &&
      parsedRedirectTo.tld === parsedLocationHref.tld
    ) {
      w.location.href = PORTAL_PAGE_URL;
    } else {
      w.location.href = PORTAL_PAGE_URL;
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    await axios.post(
      `${SHANGHAI_API_ENDPOINT}/sessionTokens`,
      {
        email,
        password,
        apiKey: SHANGHAI_API_KEY
      },
      {
        withCredentials: true
      }
    );
  };

  const isLoggedIn = async (): Promise<boolean> => {
    try {
      await axios.get(`${SHANGHAI_API_ENDPOINT}/sessionTokens`, {
        withCredentials: true
      });
      return true;
    } catch {
      return false;
    }
  };

  /**
   * 仮パスワードの発行
   * @param email
   */
  const issueTemporaryPassword = async (email: string): Promise<void> => {
    await axios.put(
      `${SHANGHAI_API_ENDPOINT}/accounts/temporaryPassword`,
      {
        email
      },
      {
        withCredentials: true
      }
    );
  };

  return {
    login,
    isLoggedIn,
    issueTemporaryPassword,
    redirect
  };
};
