import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { notification } from "./modules/notification/reducers";

export const history = createBrowserHistory();

const reducer = combineReducers({
  notification,
  router: connectRouter(history)
});

export type StoreState = ReturnType<typeof reducer>;

const composeEnhancers = (() => {
  return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
})();

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);

export function subscribe(callback: (state: StoreState) => void) {
  return store.subscribe(() => {
    callback(store.getState());
  });
}
