import React, { useEffect } from "react";
import { NotLoggedInLayout } from "../../components/NotLoggedInLayout";
import { SimpleFormBox } from "../../components/SimpleFormBox";
import { NewPasswordForm } from "./NewPasswordForm";
import { useLogin } from "../../hooks/useLogin";
import { TemporaryPasswordForm } from "./TemporaryPasswordForm";
import { useResetPasswordPage } from "./hooks";

export const ResetPasswordPage: React.FunctionComponent = () => {
  const { isLoggedIn, redirect } = useLogin();
  const redirectIfLoggedIn = async () => {
    if (await isLoggedIn()) {
      redirect();
    }
  };

  useEffect(() => {
    void redirectIfLoggedIn();
  });

  const {
    email,
    temporaryPassword,
    validateTemporaryPassword,
    sendResetPassword
  } = useResetPasswordPage();

  return (
    <NotLoggedInLayout>
      <SimpleFormBox>
        {email && temporaryPassword ? (
          <NewPasswordForm
            email={email}
            temporaryPassword={temporaryPassword}
            sendResetPassword={sendResetPassword}
          />
        ) : (
          <TemporaryPasswordForm
            validateTemporaryPassword={validateTemporaryPassword}
          />
        )}
      </SimpleFormBox>
    </NotLoggedInLayout>
  );
};
