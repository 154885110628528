import React from "react";
import { GlobalHeader } from "../../components/GlobalHeader";
import { Global } from "@emotion/core";
import { css } from "emotion";

const postMessage = (data: { type: string; [k: string]: any }) => {
  if (window.parent) {
    window.parent.postMessage(JSON.parse(JSON.stringify(data)), "*");
  }
};

export const HeaderIFramePageForPaymentTerminal: React.FunctionComponent = () => {
  return (
    <div
      className={css({
        height: "100vh"
      })}
      onClick={() => postMessage({ type: "AppHeader:onClickInner" })}
      onMouseEnter={() => postMessage({ type: "AppHeader:onMouseEnter" })}
    >
      <Global
        styles={{
          "html,body,#root": {
            backgroundColor: "transparent"
          }
        }}
      />
      <GlobalHeader
        product="receivable-payment-terminal"
        onLoadAccount={account =>
          postMessage({ type: "AppHeader:onLoadAccount", account })
        }
        onLoadBrooklynAccount={brooklynAccount =>
          postMessage({
            type: "AppHeader:onLoadBrooklynAccount",
            brooklynAccount
          })
        }
        onLoadNotices={notices =>
          postMessage({ type: "AppHeader:onLoadNotices", notices })
        }
        onLoadProducts={products =>
          postMessage({ type: "AppHeader:onLoadProducts", products })
        }
        onRedirect={redirectingUrl =>
          postMessage({ type: "AppHeader:onRedirect", redirectingUrl })
        }
        onClickMenu={() => postMessage({ type: "AppHeader:onClickMenu" })}
      />
      <div
        className={css({ height: "calc(100vh - 67px)" })}
        onClick={event => {
          postMessage({ type: "AppHeader:onClickOuter" });
          event.stopPropagation();
        }}
        onMouseOver={() => postMessage({ type: "AppHeader:onMouseLeave" })}
      />
    </div>
  );
};
