import { useCallback, useState } from "react";
import axios from "axios";
import { SHANGHAI_API_ENDPOINT } from "../../constants";
import { TemporaryPasswordParams, RegistrationParams } from "./types";
import { useNotification } from "../../modules/notification/hooks";

interface State {
  email?: string;
  temporaryPassword?: string;
  step: number;
}

const initialState = {
  email: undefined,
  temporaryPassword: undefined,
  step: 1
};

export const useRegistration = () => {
  const { showMessage } = useNotification();
  const [state, update] = useState<State>(initialState);

  /**
   * 仮パスワードの有効性確認
   */
  const validateTemporaryPassword = useCallback(
    async (params: TemporaryPasswordParams): Promise<void> => {
      try {
        await axios.put(
          `${SHANGHAI_API_ENDPOINT}/accounts/temporaryPassword/validity`,
          params,
          {
            withCredentials: true
          }
        );
        update({
          ...state,
          email: params.email,
          temporaryPassword: params.temporaryPassword,
          step: 2
        });
        await showMessage("仮パスワードを確認しました");
      } catch (e) {
        await showMessage("メールアドレスと仮パスワードの組み合わせが不正です");
      }
    },
    [showMessage, state]
  );

  /**
   * パスワードの登録
   */
  const registrationRequest = useCallback(
    async (params: RegistrationParams) => {
      try {
        await axios.post(
          `${SHANGHAI_API_ENDPOINT}/accounts/invitations/accept`,
          params,
          {
            withCredentials: true
          }
        );
        update({
          ...state,
          step: 3
        });
        showMessage("アカウントの設定が完了しました");
      } catch (e) {
        showMessage("予期せぬエラーが発生しました");
      }
    },
    [showMessage, state]
  );

  return {
    validateTemporaryPassword,
    registrationRequest,
    email: state.email,
    step: state.step,
    temporaryPassword: state.temporaryPassword
  };
};
