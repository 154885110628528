import React from "react";
import { Formik, FormikActions } from "formik";
import { css } from "emotion";
import { Button } from "chamber";
import { FormikErrors } from "formik";
import * as Form from "../../styles/Form";

interface FormValues {
  email: string;
  temporaryPassword: string;
}

interface Props {
  validateTemporaryPassword: (params: FormValues) => Promise<void>;
}

export const validator = (formValues: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};

  if (!formValues.email) {
    errors.email = "メールアドレスが入力されていません";
  } else if (!formValues.email.includes("@")) {
    errors.email = "メールアドレスの形式が不正です";
  }
  if (!formValues.temporaryPassword) {
    errors.temporaryPassword = "仮パスワードが入力されていません";
  }

  return errors;
};

export const TemporaryPasswordForm: React.FunctionComponent<Props> = ({
  validateTemporaryPassword
}) => {
  const handleSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      await validateTemporaryPassword(values);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <p
        className={css`
          font-size: 12px;
        `}
      >
        メールアドレスと仮パスワードを入力してください。
      </p>
      <Formik<FormValues>
        initialValues={{
          email: "",
          temporaryPassword: ""
        }}
        onSubmit={handleSubmit}
        validate={validator}
        render={({
          handleSubmit,
          handleBlur,
          values,
          handleChange,
          isSubmitting,
          errors,
          dirty,
          touched
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Form.Input
                label="メールアドレス"
                name="email"
                value={values.email}
                placeholder="クリックして入力"
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                status={touched.email && !!errors.email ? "error" : undefined}
              />
              {touched.email && !!errors.email && (
                <Form.ErrorText>{errors.email}</Form.ErrorText>
              )}
              <Form.Input
                label="仮パスワード"
                name="temporaryPassword"
                value={values.temporaryPassword}
                placeholder="クリックして入力"
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                status={
                  touched.temporaryPassword && !!errors.temporaryPassword
                    ? "error"
                    : undefined
                }
              />
              {touched.temporaryPassword && !!errors.temporaryPassword && (
                <Form.ErrorText>{errors.temporaryPassword}</Form.ErrorText>
              )}
              <div
                className={css`
                  text-align: center;
                  margin-top: 25px;
                `}
              >
                <Button
                  type="submit"
                  color="accent"
                  disabled={!dirty || isSubmitting}
                  className={css({
                    fontSize: "12px",
                    minWidth: "120px"
                  })}
                >
                  パスワード再設定
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
