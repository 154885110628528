import React, { useCallback, useEffect } from "react";
import { NotLoggedInLayout } from "../../components/NotLoggedInLayout";
import { SimpleFormBox } from "../../components/SimpleFormBox";
import { Button } from "chamber";
import { Formik, FormikValues, FormikActions } from "formik";
import { css } from "emotion";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import { SHANGHAI_API_ENDPOINT } from "../../constants";
import axios from "axios";
import { useLogin } from "../../hooks/useLogin";
import { validator } from "./validator";
import * as Form from "../../styles/Form";
import { useNotification } from "../../modules/notification/hooks";

export interface FormValues {
  email: string;
}

export const PrepareResetPasswordPage: React.FunctionComponent = () => {
  const { isLoggedIn, redirect } = useLogin();
  const { showMessage } = useNotification();

  const redirectIfLoggedIn = async () => {
    if (await isLoggedIn()) {
      redirect();
    }
  };

  useEffect(() => {
    void redirectIfLoggedIn();
  });

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikActions<FormikValues>
  ) => {
    try {
      await axios.put(
        `${SHANGHAI_API_ENDPOINT}/accounts/temporaryPassword`,
        {
          email: values.email
        },
        {
          withCredentials: true
        }
      );
      showMessage("パスワード再発行のご案内メールを送信しました");
      actions.resetForm();
    } catch {
      actions.setStatus("メールアドレスが不正です");
    } finally {
      actions.setSubmitting(false);
    }
  };

  const renderLoginPageLink = useCallback(() => {
    return (
      <div
        className={css`
          margin-top: 5px;
          font-size: 12px;
        `}
      >
        <Link
          to="/login"
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon>arrow_back</Icon>
          ログイン画面へ戻る
        </Link>
      </div>
    );
  }, []);

  return (
    <NotLoggedInLayout>
      <SimpleFormBox renderLink={renderLoginPageLink}>
        <div>
          <p
            className={css`
              font-size: 12px;
            `}
          >
            パスワード再設定メールをお送りいたしますので、ご登録のメールアドレスを入力してください。
          </p>
          <Formik<FormValues>
            initialValues={{
              email: ""
            }}
            validate={validator}
            onSubmit={handleSubmit}
            render={({
              handleSubmit,
              values,
              handleChange,
              isSubmitting,
              errors,
              handleBlur,
              touched,
              dirty
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Form.Input
                    label="メールアドレス"
                    name="email"
                    value={values.email}
                    placeholder="クリックして入力"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    status={
                      touched.email && !!errors.email ? "error" : undefined
                    }
                    type="email"
                  />
                  {touched.email && !!errors.email && (
                    <Form.ErrorText>{errors.email}</Form.ErrorText>
                  )}
                  <div
                    className={css`
                      text-align: center;
                      margin-top: 25px;
                    `}
                  >
                    <Button
                      type="submit"
                      color="accent"
                      disabled={!dirty || isSubmitting}
                      className={css({
                        fontSize: "12px",
                        minWidth: "120px"
                      })}
                    >
                      再設定メール送信
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </SimpleFormBox>
    </NotLoggedInLayout>
  );
};
