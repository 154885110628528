const errorEnvValue = (environmentVariableName: string): never => {
  throw new Error(
    `Required environment variable ${environmentVariableName} not found.`
  );
};

export const APPLICATION_ENVIRONMENT:
  | "develop"
  | "staging"
  | "production" = process.env.REACT_APP_APPLICATION_ENVIRONMENT as any;
if (
  APPLICATION_ENVIRONMENT !== "develop" &&
  APPLICATION_ENVIRONMENT !== "staging" &&
  APPLICATION_ENVIRONMENT !== "production"
) {
  throw new Error(
    `Unsupported REACT_APP_APPLICATION_ENVIRONMENT value: ${APPLICATION_ENVIRONMENT}`
  );
}

export const SHANGHAI_API_ENDPOINT: string =
  process.env.REACT_APP_SHANGHAI_API_ENDPOINT ||
  errorEnvValue("REACT_APP_SHANGHAI_API_ENDPOINT");
export const SHANGHAI_API_KEY: string =
  process.env.REACT_APP_SHANGHAI_API_KEY ||
  errorEnvValue("REACT_APP_SHANGHAI_API_KEY");

export const PORTAL_PAGE_URL: string =
  process.env.REACT_APP_PORTAL_PAGE_URL ||
  errorEnvValue("REACT_APP_PORTAL_PAGE_URL");

export const COPENHAGEN_API_ENDPOINT: string =
  process.env.REACT_APP_COPENHAGEN_API_ENDPOINT ||
  errorEnvValue("REACT_APP_COPENHAGEN_API_ENDPOINT");

export const USHUAIA_API_ENDPOINT: string =
  process.env.REACT_APP_USHUAIA_API_ENDPOINT ||
  errorEnvValue("REACT_APP_USHUAIA_API_ENDPOINT");

export const GA_TRACKING_ID: string =
  process.env.REACT_APP_GA_TRACKING_ID ||
  errorEnvValue("REACT_APP_GA_TRACKING_ID");

export const SALES_COLLECTION_FORM_LINK: string =
  "https://docs.google.com/forms/d/e/1FAIpQLSeSntkpZlWosIOwmBCaxH4NgfBSNKVJgNijTWtEFJQv6EWCcA/viewform?c=0&w=1";

export const TABLET_PARTS_ORDER_FORM_LINK: string =
  "https://docs.google.com/forms/d/e/1FAIpQLScHiB_UYeBvQZTtY1D_hTqXgEMfzEdPE26nkoZcMh8rbK3B7w/viewform";

export const PAYMENT_STICKER_FORM_LINK: string =
  "https://forms.gle/PxcxCdKVPYWMdTUU7";

export const INITIAL_TABLET_SETTING_FORM_LINK: string =
  "https://docs.google.com/forms/d/e/1FAIpQLScVuuoUxa-tGSqwUmg2kbClYTJej0wsPOqAdsyFzF-rE2hNqg/viewform";

export const GO_MANAGEMENT_PAGE_URL: string =
  process.env.REACT_APP_GO_MANAGEMENT_PAGE_URL ||
  errorEnvValue("REACT_APP_GO_MANAGEMENT_PAGE_URL");
