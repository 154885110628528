import React from "react";
import { GlobalHeader } from "../../components/GlobalHeader";
import { css } from "emotion";
// import { Table } from "semantic-ui-react";
import { OuterLink } from "../../components/OuterLink";
// import format from "date-fns/format";
// import { useManualPage } from "./hooks";
import { Helmet } from "react-helmet";
import { AlertArea, DescriptionList } from "../../styles/alert";
import { GO_MANAGEMENT_PAGE_URL } from "../../constants";

export const ManualPage: React.FunctionComponent = () => {
  // const { fetchManuals, isFetching, isError, manuals } = useManualPage();
  //
  // React.useEffect(() => {
  //   void fetchManuals();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={css({ height: "100%" })}>
      <Helmet>
        <title>マニュアル | JapanTaxi Partner Platform</title>
      </Helmet>
      <GlobalHeader product="manual" />
      <div
        className={css({
          display: "flex",
          justifyContent: "center",
          marginTop: "5em"
        })}
      >
        <AlertArea>
          <DescriptionList>
            <dt>
              2025年2月12日以降はGO管理画面でお知らせの確認をお願いいたします
            </dt>
          </DescriptionList>
          <OuterLink
            to={GO_MANAGEMENT_PAGE_URL + "/documents"}
            target={"_blank"}
          >
            GO管理画面の 資料一覧に移動
          </OuterLink>
        </AlertArea>

        {/*{isFetching && "取得中です……"}*/}
        {/*{isError && "取得に失敗しました。時間をおいてもう一度お試しください。"}*/}
        {/*{manuals && (*/}
        {/*  <Table striped className={css({ width: "80% !important" })}>*/}
        {/*    <Table.Header>*/}
        {/*      <Table.Row>*/}
        {/*        <Table.HeaderCell>リンク</Table.HeaderCell>*/}
        {/*        <Table.HeaderCell>説明</Table.HeaderCell>*/}
        {/*        <Table.HeaderCell>更新日</Table.HeaderCell>*/}
        {/*      </Table.Row>*/}
        {/*    </Table.Header>*/}
        {/*    <Table.Body>*/}
        {/*      {manuals.map(manual => (*/}
        {/*        <Table.Row key={manual.id}>*/}
        {/*          <Table.Cell>*/}
        {/*            <OuterLink to={manual.url}>{manual.title}</OuterLink>*/}
        {/*          </Table.Cell>*/}
        {/*          <Table.Cell>{manual.detail}</Table.Cell>*/}
        {/*          <Table.Cell>*/}
        {/*            {format(new Date(manual.updateDate), "YYYY-MM-DD")}*/}
        {/*          </Table.Cell>*/}
        {/*        </Table.Row>*/}
        {/*      ))}*/}
        {/*    </Table.Body>*/}
        {/*  </Table>*/}
        {/*)}*/}
      </div>
    </div>
  );
};
