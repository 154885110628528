import React, { useCallback, useState } from "react";
import { GlobalHeader } from "../../components/GlobalHeader";
import {
  homeProduct,
  receivableProduct,
  receivablePaymentTerminalProduct,
  paymentAndBillingProduct,
  Notice,
  goProduct
} from "chamber";
import { Divider } from "semantic-ui-react";
import { css } from "emotion";
import { ChamberProduct } from "./type";
import { ServiceLink } from "./ServiceLink";
import { NoticeMessage } from "./NoticeMessage";
import {
  SALES_COLLECTION_FORM_LINK,
  TABLET_PARTS_ORDER_FORM_LINK,
  PAYMENT_STICKER_FORM_LINK,
  INITIAL_TABLET_SETTING_FORM_LINK,
  GO_MANAGEMENT_PAGE_URL
} from "../../constants";
import { AnotherServiceLink } from "./AnotherServiceLink";
import { Gray300, White } from "chamber/lib/designs";
import { AlertArea, DescriptionList } from "../../styles/alert";
import { OuterLink } from "../../components/OuterLink";

export const HomePage: React.FunctionComponent = () => {
  const [products, setProducts] = useState<ChamberProduct[] | null>(null);
  const [notices, setNotices] = useState<Notice[] | null>(null);
  const salesCorrectionLinkPermissions = [
    receivableProduct.type,
    receivablePaymentTerminalProduct.type,
    paymentAndBillingProduct.type
  ];

  const renderProducts = useCallback(() => {
    if (products == null) return "読み込み中……";

    return products
      .filter(product => product.type !== homeProduct.type)
      .map(product => (
        <ServiceLink
          key={product.title}
          title={product.title}
          url={product.url}
          icon={product.logoData}
          isOuterLink={product.isOuterLink}
          imageSize={product.type === goProduct.type ? "large" : "small"}
        />
      ));
  }, [products]);

  const renderNotices = useCallback(() => {
    if (notices == null) return "読み込み中……";

    return notices.map(notice => (
      <NoticeMessage key={notice.id} notice={notice} />
    ));
  }, [notices]);

  const renderAnotherServiceLink = useCallback(() => {
    if (products == null) return "読み込み中……";
    const hasPermission = (product: ChamberProduct) =>
      salesCorrectionLinkPermissions.includes(product.type);

    const displaySalesCorrectionLink = [products.find(hasPermission)]
      .filter(Boolean)
      .map(_ => (
        <>
          <AnotherServiceLink
            key={Math.random()}
            title="決済機 売上・データ訂正依頼フォーム"
            url={SALES_COLLECTION_FORM_LINK}
          />
          <AnotherServiceLink
            key={Math.random()}
            title="タブレット部品追加注文見積依頼フォーム"
            url={TABLET_PARTS_ORDER_FORM_LINK}
          />
          <AnotherServiceLink
            key={Math.random()}
            title="決済関連ステッカー追加依頼フォーム"
            url={PAYMENT_STICKER_FORM_LINK}
          />
        </>
      ));

    return (
      <>
        {displaySalesCorrectionLink}
        <AnotherServiceLink
          key={Math.random()}
          title="電子マネー決済 端末初期登録情報の追加依頼フォーム"
          url={INITIAL_TABLET_SETTING_FORM_LINK}
        />
      </>
    );
  }, [products, salesCorrectionLinkPermissions]);

  return (
    <div>
      <GlobalHeader
        product="home"
        onLoadProducts={setProducts}
        onLoadNotices={setNotices}
      />
      <div
        className={css({
          height: "calc(100vh - 68px)",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center"
        })}
      >
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          })}
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              width: "30em",
              margin: "0 1em"
            })}
          >
            <Divider horizontal>提供サービス</Divider>
            {renderProducts()}

            <Divider horizontal>関連サービスリンク</Divider>
            {renderAnotherServiceLink()}

            <Divider horizontal>問い合わせ窓口</Divider>
            <div
              className={css({
                margin: "0.5em 0"
              })}
            >
              <div
                className={css({
                  width: "100%",
                  padding: "1em",
                  border: `solid 1px ${Gray300}`,
                  borderRadius: ".5em",
                  backgroundColor: White,
                  "& + &": {
                    borderTop: 0,
                    margin: "1em 0"
                  }
                })}
              >
                <div>管理者様専用窓口（GOタクシーサポート）</div>
                <div>TEL : 050−3183−8895</div>
                <div>※乗務員様へこちらの番号のご共有はお控えください。</div>
              </div>
            </div>
            <p
              className={css({
                marginTop: "1em"
              })}
            >
              ※QRコードは(株)デンソーウェーブの登録商標です。
            </p>
          </div>

          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              width: "30em",
              margin: "0 1em"
            })}
          >
            <Divider horizontal>お知らせ</Divider>
            <AlertArea>
              <DescriptionList>
                <dt>
                  2025年2月12日以降はGO管理画面でお知らせの確認をお願いいたします
                </dt>
              </DescriptionList>
              <OuterLink
                to={GO_MANAGEMENT_PAGE_URL + "/notices"}
                target={"_blank"}
              >
                GO管理画面の お知らせに移動
              </OuterLink>
            </AlertArea>
            <div
              className={css({
                margin: "0.5em 0"
              })}
            >
              {renderNotices()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
