import React from "react";
import { Formik } from "formik";
import { Button } from "chamber";
import { css } from "emotion";
import { FormValues, useLoginForm } from "./hooks";
import * as Form from "../../styles/Form";

export const LoginForm: React.FunctionComponent = () => {
  const { formikProps } = useLoginForm();

  return (
    <Formik<FormValues> {...formikProps}>
      {({
        handleSubmit,
        errors,
        isSubmitting,
        touched,
        values,
        handleChange,
        handleBlur,
        dirty
      }) => (
        <form
          className={css`
            width: 100%;
          `}
          onSubmit={handleSubmit}
        >
          <Form.Input
            label="メールアドレス"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="クリックして入力"
            type="text"
            status={touched.email && !!errors.email ? "error" : undefined}
          />
          {touched.email && !!errors.email && (
            <Form.ErrorText>{errors.email}</Form.ErrorText>
          )}
          <Form.Input
            className={css`
              margin-top: 10px;
            `}
            label="パスワード"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="クリックして入力"
            type="password"
            status={touched.password && !!errors.password ? "error" : undefined}
          />
          {touched.password && !!errors.password && (
            <Form.ErrorText>{errors.password}</Form.ErrorText>
          )}
          <div
            className={css`
              text-align: center;
              margin-top: 25px;
            `}
          >
            <Button
              type="submit"
              color="accent"
              disabled={!dirty || isSubmitting}
              className={css({
                fontSize: "12px",
                width: "120px"
              })}
            >
              ログイン
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
