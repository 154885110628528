import React from "react";
import { Notice } from "chamber";
import reactStringReplace from "react-string-replace";
import { css } from "emotion";
import { Gray300, Gray700, Red500, White } from "chamber/lib/designs";
import format from "date-fns/format";
import styled from "@emotion/styled";

export interface Props {
  notice: Notice;
}

const Category = styled.div(props => ({
  marginLeft: "1em",
  display: "block",
  textAlign: "center",
  border: "solid 1px",
  color: props.color
}));

export class NoticeMessage extends React.Component<Props> {
  render() {
    const { notice } = this.props;

    return (
      <div
        className={css({
          width: "100%",
          padding: "1em",
          border: `solid 1px ${Gray300}`,
          borderRadius: ".5em",
          backgroundColor: White,
          "& + &": {
            borderTop: 0,
            margin: "1em 0"
          }
        })}
      >
        <div
          className={css({
            display: "flex",
            marginBottom: "0.5em",
            fontSize: "0.7em",
            color: Gray700
          })}
        >
          <div>{format(new Date(notice.releaseDate), "YYYY年MM月DD日")}</div>
          {notice.isImportant && (
            <Category
              color={Red500 || "transparent"}
              style={{
                width: "5em"
              }}
            >
              重要
            </Category>
          )}
          <Category
            color={notice.categoryColor || "transparent"}
            style={{
              width: "10em"
            }}
          >
            {notice.categoryName}
          </Category>
        </div>
        <div>{this.newLineToBreak(this.urlToLink(notice.title))}</div>
      </div>
    );
  }

  private newLineToBreak = (node: string | React.ReactNodeArray) =>
    reactStringReplace(node, /(\r\n|\n|\r)/g, () => (
      <br key={`br_${Math.random()}`} />
    ));

  private urlToLink = (node: string) =>
    reactStringReplace(
      node,
      /(\[.*\]\(https?:\/\/[-_.!~*\\'()a-zA-Z0-9;/?:@&=+$,%#]+\))/,
      match => {
        const x = /\[(.*)\]\((https?:\/\/[-_.!~*\\'()a-zA-Z0-9;/?:@&=+$,%#]+)\)/.exec(
          match
        );
        return (
          x && (
            <a key={`link_${Math.random()}`} href={x[2]}>
              {x[1]}
            </a>
          )
        );
      }
    );
}
