import styled from "@emotion/styled";
import * as color from "chamber/lib/designs";
import { Button } from "chamber";

export const ButtonRegister = styled(Button)`
  font-size: 12px;
  width: 120px;
  font-weight: normal;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
`;

export const Summary = styled.div`
  padding: 65px 25px 0;
  color: ${color.White};
  flex-basis: 50%;
  background: ${color.JtxBlue} url("/JapanTaxi_logo_white.png") no-repeat left
    25px bottom 15px;
  background-size: 115px 27px;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 20px;
`;

export const Caption = styled.p`
  font-size: 10px;
  line-height: 1.6;
`;

export const Content = styled.div`
  padding: 20px;
  flex: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormAction = styled.div`
  margin-top: auto;
  text-align: right;
`;
