import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { HomePage } from "./features/HomePage";
import { HeaderIFramePageForPaymentTerminal } from "./features/HeaderIframeForPaymentTerminalPage";
import { ManualPage } from "./features/ManualPage";
import { LoginPage } from "./features/LoginPage";
import { PrepareResetPasswordPage } from "./features/PrepareResetPasswordPage";
import { ResetPasswordPage } from "./features/ResetPasswordPage";
import ReactGa from "react-ga";
import { RegistrationForm } from "./features/RegistrationPage";

const decorateRoute = (
  routeElement: React.ReactElement
): React.FunctionComponent => () => {
  useEffect(() => {
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return routeElement;
};

export const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/" exact component={decorateRoute(<HomePage />)} />
      <Route path="/manual" exact component={decorateRoute(<ManualPage />)} />
      <Route
        path="/header_iframe_for_payment_terminal"
        exact
        render={() => <HeaderIFramePageForPaymentTerminal />}
      />
      <Route path="/login" component={decorateRoute(<LoginPage />)} />
      <Route
        path="/prepare-reset-password"
        exact
        component={decorateRoute(<PrepareResetPasswordPage />)}
      />
      <Route
        path="/reset-password"
        exact
        component={decorateRoute(<ResetPasswordPage />)}
      />
      <Route
        path="/accept-invitation"
        exact
        component={decorateRoute(<RegistrationForm />)}
      />
      <Route component={decorateRoute(<Redirect to="/" />)} />
    </Switch>
  );
};
