import React from "react";
import * as color from "chamber/lib/designs";
import styled from "@emotion/styled";
import { iconPseudoStyle } from "../../styles/iconPseudo";

const Header = styled.header<{ step: number }>`
  padding: 25px 20px;
  border-bottom: 1px solid ${color.Gray300};
  position: relative;
  &::after {
    content: "";
    bottom: -1px;
    left: 0;
    height: 3px;
    background: ${color.InputColor};
    display: block;
    position: absolute;
  }
  ${props => {
    // プログレスバーのスタイル
    switch (props.step) {
      case 1:
        return `
          &::after {
            width: 50%;
          }
        `;
      case 2:
        return `
          &::after {
            width: 75%;
          }
        `;
      case 3:
        return `
          &::after {
            width: 100%;
          }
        `;
      default:
        return `
          &::after {
            width: 0;
          }
        `;
    }
  }}
`;

const StepList = styled.ol`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

type StepItemProps = {
  active: boolean;
  completed: boolean;
};

const StepItem = styled.li<StepItemProps>`
  font-size: 10px;
  align-items: center;
  display: flex;
  color: ${color.Gray300};
  &::before {
    content: "";
    margin-right: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${color.Gray300};
  }
  &::after {
    content: "";
    width: 50px;
    height: 2px;
    display: block;
    margin-left: 10px;
    background: ${color.Gray300};
    border-radius: 4px;
  }
  ${props =>
    props.active &&
    `
      color: ${color.MultiActionColor};
      &::before {
        background: ${color.MultiActionColor};
      }
    `}
  ${props =>
    props.completed &&
    `
      color: ${color.InputColor};
      &::before {
        content: 'check_circle';
        ${iconPseudoStyle};
        background: none;
        width: auto;
        height: auto;
        border-radius: 0;
        font-size: 14px;
      }
      &::after {
        background: ${color.InputColor};
      }
    `}
  &:last-of-type {
    &::after {
      content: none;
    }
  }
`;

export const RegistrationHeader: React.FunctionComponent<{ step: number }> = ({
  step
}) => {
  return (
    <Header step={step}>
      <StepList>
        <StepItem completed active={false}>
          メール認証
        </StepItem>
        <StepItem completed={step > 1} active={step === 1}>
          仮パスワード入力
        </StepItem>
        <StepItem completed={step > 2} active={step === 2}>
          新しいパスワード設定
        </StepItem>
        <StepItem completed={step > 2} active={false}>
          登録完了
        </StepItem>
      </StepList>
    </Header>
  );
};
