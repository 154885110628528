import * as React from "react";
import { css } from "emotion";
import { OuterLink } from "../../components/OuterLink";
import { Gray300, White } from "chamber/lib/designs";

export interface Props {
  title: string;
  url: string;
}

export const AnotherServiceLink: React.FunctionComponent<Props> = ({
  url,
  title
}) => (
  <div
    className={css({
      width: "100%",
      padding: "1em",
      border: `solid 1px ${Gray300}`,
      borderRadius: ".5em",
      backgroundColor: White,
      marginBottom: ".5em"
    })}
  >
    <div>
      <OuterLink to={url} target="_blank">
        {title}
      </OuterLink>
    </div>
  </div>
);
