import React, { useEffect, useCallback } from "react";
import { LoginForm } from "./LoginForm";
import { useLogin } from "../../hooks/useLogin";
import { NotLoggedInLayout } from "../../components/NotLoggedInLayout";
import { css } from "emotion";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import { SimpleFormBox } from "../../components/SimpleFormBox";

export const LoginPage: React.FunctionComponent = () => {
  const { isLoggedIn, redirect } = useLogin();

  const redirectIfLoggedIn = async () => {
    if (await isLoggedIn()) {
      redirect();
    }
  };

  useEffect(() => {
    void redirectIfLoggedIn();
  });

  const renderResetPassword = useCallback(() => {
    return (
      <div
        className={css`
          text-align: right;
          margin-top: 5px;
          font-size: 12px;
        `}
      >
        <Link
          to="/prepare-reset-password"
          className={css`
            display: inline-flex;
            align-items: center;
          `}
        >
          パスワードを再発行
          <Icon>arrow_forward</Icon>
        </Link>
      </div>
    );
  }, []);

  return (
    <NotLoggedInLayout>
      <SimpleFormBox renderLink={renderResetPassword}>
        <LoginForm />
      </SimpleFormBox>
    </NotLoggedInLayout>
  );
};
