import styled from "@emotion/styled";
import { TextInput } from "chamber";
import * as Color from "chamber/lib/designs";

export const Input = styled(TextInput)`
  font-size: 12px;
  width: 100%;
`;

export const ErrorText = styled.p`
  color: ${Color.ErrorTextColor};
  margin: 5px 0;
  font-size: 12px;
  text-align: left;
`;

export const SimpleContainer = styled.div`
  background: ${Color.White};
  border-radius: 8px;
  width: 330px;
  min-height: 330px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
