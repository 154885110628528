import React, { useState } from "react";
import { Formik, FormikActions, FormikErrors } from "formik";
import { css } from "emotion";
import { Button } from "chamber";
import styled from "@emotion/styled";
import { useLogin } from "../../hooks/useLogin";
import * as Color from "chamber/lib/designs";
import { sleep } from "../../utils/sleep";
import * as Form from "../../styles/Form";
import { useNotification } from "../../modules/notification/hooks";

const VisualizePasswordIcon = styled.i<{ active: boolean }>`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: ${Color.MultiActionColor};
  font-family: "Material Icons", serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  &::before {
    content: "visibility";
    display: block;
  }
  ${props =>
    props.active &&
    `
    cursor: pointer;
    &::before {
      content: 'visibility_off';
    }
  `}
`;

interface FormValues {
  email: string;
  temporaryPassword: string;
  password: string;
}

interface Props {
  email: string;
  temporaryPassword: string;
  sendResetPassword: (params: FormValues) => Promise<void>;
}

export const validator = (formValues: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};

  if (!formValues.password) {
    errors.password = "パスワードが入力されていません";
  } else if (
    !/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d@%+/'!#$^?:,(){}[\]~\-_.]{8,64}$/.test(
      formValues.password
    )
  ) {
    errors.password =
      "パスワードは8文字以上64文字以下、大文字、小文字、数字を含めてください。使える記号は(@%+/'!#$^?:,(){}[]~-_.)のみです";
  }

  return errors;
};

export const NewPasswordForm: React.FunctionComponent<Props> = ({
  sendResetPassword,
  email,
  temporaryPassword
}) => {
  const [visible, setVisible] = useState(false);
  const { showMessage } = useNotification();
  const { redirect } = useLogin();

  const handleSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    try {
      await sendResetPassword(values);
      showMessage("パスワードの設定が完了しました");
      await sleep(2);
      redirect();
    } catch (e) {
      showMessage("予期せぬエラーが発生しました");
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <div>
      <p
        className={css`
          font-size: 12px;
        `}
      >
        ログインに用いる新しいパスワードを設定してください
      </p>
      <p
        className={css`
          font-size: 10px;
          color: ${Color.SecondaryTextColor};
          line-height: 1.6;
        `}
      >
        パスワードはアルファベットの大文字、小文字、数字の三種類を必ず使用し、8文字以上64文字以下で入力してください。
      </p>
      <Formik<FormValues>
        initialValues={{
          email,
          temporaryPassword,
          password: ""
        }}
        onSubmit={handleSubmit}
        validate={validator}
        render={({
          handleSubmit,
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          dirty,
          errors,
          touched
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={css`
                  position: relative;
                `}
              >
                <Form.Input
                  label="新しいパスワード"
                  name="password"
                  value={values.password}
                  placeholder="クリックして入力"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type={visible ? "text" : "password"}
                  status={
                    touched.password && !!errors.password ? "error" : undefined
                  }
                />
                <VisualizePasswordIcon
                  active={visible}
                  onClick={() => setVisible(!visible)}
                />
              </div>
              {touched.password && !!errors.password && (
                <Form.ErrorText>{errors.password}</Form.ErrorText>
              )}
              <div
                className={css`
                  text-align: center;
                  margin-top: 25px;
                `}
              >
                <Button
                  type="submit"
                  color="accent"
                  disabled={!dirty || isSubmitting}
                  className={css({
                    fontSize: "12px",
                    minWidth: "120px"
                  })}
                >
                  パスワード再設定
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
