import React from "react";

export interface Props {
  to?: string;
  target?: "_blank" | "_self" | "_top" | "_parent";
}

export const OuterLink: React.FunctionComponent<
  Props &
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >
> = ({ to, target = "_blank", children, href, rel, ...otherProps }) => (
  <a href={to} target={target} rel="noopener noreferrer" {...otherProps}>
    {children}
  </a>
);
