import React from "react";
import { AppHeader, AppHeaderProps } from "chamber";
import { APPLICATION_ENVIRONMENT } from "../constants";

const getEnv = () => {
  return {
    develop: "dev" as const,
    staging: "stg" as const,
    production: "prod" as const
  }[APPLICATION_ENVIRONMENT];
};

export const GlobalHeader: React.FunctionComponent<
  Omit<AppHeaderProps, "env">
> = props => {
  return <AppHeader env={getEnv()} {...props} />;
};
