import React from "react";
import * as color from "chamber/lib/designs";
import * as Style from "./style";
import { css } from "emotion";
import { PORTAL_PAGE_URL } from "../../constants";

export const RegistrationCompleted: React.FunctionComponent = () => {
  return (
    <Style.Wrapper
      className={css`
        flex-direction: column;
        padding: 20px;
      `}
    >
      <div
        className={css`
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          className={css`
            text-align: center;
          `}
        >
          <h1
            className={css`
              font-weight: bold;
              font-size: 18px;
              margin-bottom: 20px;
              color: ${color.InputColor};
            `}
          >
            お疲れ様でした！
          </h1>
          <Style.Caption>アカウントの準備が完了いたしました</Style.Caption>
        </div>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        `}
      >
        <img
          className={css`
            //画像位置の調整
            position: relative;
            top: 5px;
            margin-left: 5px;
          `}
          src="/JapanTaxi_logo.png"
          alt=""
          width="115"
          height="28"
        />
        <Style.ButtonRegister
          onClick={(e: { preventDefault: () => void }, w = window) => {
            e.preventDefault();
            w.location.href = PORTAL_PAGE_URL;
          }}
          color="accent"
          type="button"
        >
          利用開始
        </Style.ButtonRegister>
      </div>
    </Style.Wrapper>
  );
};
