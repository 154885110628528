import { CreatorsToActions } from "../../utils/CreatorToActions";
import * as creators from "./actions";

type Actions = CreatorsToActions<typeof creators>;

export const NOTIFICATION = {
  OPEN: "NOTIFICATION_OPEN",
  CLOSE: "NOTIFICATION_CLOSE"
} as const;

interface State {
  message?: string;
  open: boolean;
}

const initialState = {
  message: undefined,
  open: false
};

export const notification = (
  state: State = initialState,
  actions: Actions
): State => {
  switch (actions.type) {
    case NOTIFICATION.OPEN:
    case NOTIFICATION.CLOSE:
      return { ...state, ...actions.payload };
    default:
      return state;
  }
};
