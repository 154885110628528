import React, { useCallback, useState } from "react";
import * as Style from "./style";
import { css } from "emotion";
import { Button, TextInput } from "chamber";
import { Formik, FormikActions, FormikErrors } from "formik";
import styled from "@emotion/styled";
import * as Color from "chamber/lib/designs";
import { ErrorText } from "../../styles/Form";
import { iconPseudoStyle } from "../../styles/iconPseudo";
import { useLogin } from "../../hooks/useLogin";

const Input = styled(TextInput)`
  > label {
    font-size: 10px;
  }
  > input {
    font-size: 12px;
    height: auto;
  }
`;

const VisualizePasswordIcon = styled.i<{ active: boolean }>`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: ${Color.MultiActionColor};
  &::before {
    content: "visibility";
    ${iconPseudoStyle};
    display: block;
  }
  ${props =>
    props.active &&
    css`
      cursor: pointer;
      &::before {
        content: "visibility_off";
      }
    `}
`;

interface FormValues {
  password: string;
  email: string;
  temporaryPassword: string;
}

interface Props {
  email: string;
  temporaryPassword: string;
  registrationRequest: (values: FormValues) => Promise<void>;
}

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.password) {
    errors.password = "パスワードを入力してください";
  } else if (
    !/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d@%+/'!#$^?:,(){}[\]~\-_.]{8,64}$/.test(
      values.password
    )
  ) {
    errors.password =
      "パスワードは8文字以上64文字以下、大文字、小文字、数字を含めてください。使える記号は(@%+/'!#$^?:,(){}[]~-_.)のみです";
  }

  return errors;
};

export const RegistrationNewPasswordForm: React.FunctionComponent<Props> = ({
  email,
  temporaryPassword,
  registrationRequest
}) => {
  const [visible, setVisible] = useState(false);
  const { login } = useLogin();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>) => {
      try {
        if (email && temporaryPassword) {
          await registrationRequest({
            ...values,
            email,
            temporaryPassword
          });
          void login(email, values.password);
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [email, login, registrationRequest, temporaryPassword]
  );

  return (
    <Style.Wrapper>
      <Style.Summary>
        <Style.Title>新しいパスワードを設定してください</Style.Title>
        <Style.Caption>
          ログインに用いる新しいパスワードを設定してください
        </Style.Caption>
        <Style.Caption>
          パスワードはアルファベットの大文字、小文字、数字の三種類を必ず使用し、8文字以上64文字以下で入力してください。
        </Style.Caption>
      </Style.Summary>
      <Formik<FormValues>
        initialValues={{
          password: "",
          email,
          temporaryPassword
        }}
        validate={validate}
        onSubmit={handleSubmit}
        render={({
          values,
          handleSubmit,
          errors,
          isSubmitting,
          touched,
          handleBlur,
          handleChange,
          isValid,
          dirty
        }) => {
          return (
            <Style.Content>
              <Style.Form onSubmit={handleSubmit}>
                <div
                  className={css`
                    margin-top: 70px;
                    position: relative;
                  `}
                >
                  <Input
                    name="password"
                    label="新しいパスワード"
                    value={values.password}
                    type={visible ? "text" : "password"}
                    required
                    formSize="thin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    status={
                      touched.password && !!errors.password
                        ? "error"
                        : undefined
                    }
                    css={css`
                      width: 100%;
                    `}
                  />
                  <VisualizePasswordIcon
                    active={visible}
                    onClick={() => setVisible(!visible)}
                  />
                </div>
                {touched.password && !!errors.password && (
                  <ErrorText>{errors.password}</ErrorText>
                )}
                <Style.FormAction>
                  <Button
                    css={css`
                      width: 120px;
                      font-size: 12px;
                      font-weight: normal;
                    `}
                    type="submit"
                    color="accent"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    次へ
                  </Button>
                </Style.FormAction>
              </Style.Form>
            </Style.Content>
          );
        }}
      />
    </Style.Wrapper>
  );
};
