import { useState } from "react";
import axios from "axios";
import {
  ResetPasswordState,
  TemporaryPasswordValidParams,
  ResetPasswordParams
} from "./types";
import { SHANGHAI_API_ENDPOINT } from "../../constants";
import { useNotification } from "../../modules/notification/hooks";

const initialState = {
  valid: false,
  email: undefined,
  temporaryPassword: undefined
};

export const useResetPasswordPage = () => {
  const [state, update] = useState<ResetPasswordState>(initialState);
  const { showMessage } = useNotification();

  /**
   * 仮パスワードの有効性確認
   * @param params
   */
  const validateTemporaryPassword = async (
    params: TemporaryPasswordValidParams
  ): Promise<void> => {
    try {
      await axios.put(
        `${SHANGHAI_API_ENDPOINT}/accounts/temporaryPassword/validity`,
        params,
        {
          withCredentials: true
        }
      );
      update({
        ...state,
        valid: true,
        email: params.email,
        temporaryPassword: params.temporaryPassword
      });
      await showMessage("仮パスワードを確認しました");
    } catch (e) {
      await update({
        ...state,
        valid: false
      });
      await showMessage("メールアドレスと仮パスワードの組み合わせが不正です");
    }
  };

  /**
   * パスワード変更
   * @param params
   */
  const sendResetPassword = async (
    params: ResetPasswordParams
  ): Promise<void> => {
    await axios.put(`${SHANGHAI_API_ENDPOINT}/accounts/password`, params, {
      withCredentials: true
    });
  };

  const { valid, email, temporaryPassword } = state;

  return {
    validateTemporaryPassword,
    sendResetPassword,
    valid,
    email,
    temporaryPassword
  };
};
