import styled from "@emotion/styled";
import { ErrorTextColor } from "chamber/lib/designs";

export const AlertArea = styled.div`
  font-size: 14px;
  color: ${ErrorTextColor};
  margin-bottom: 20px;
`;

export const DescriptionList = styled.dl`
  margin-top: 10px;
  dt {
    font-weight: bold;
    font-size: 16px;
  }
`;
