import React, { useCallback } from "react";
import { TextInput, Button } from "chamber";
import * as Style from "./style";
import { css } from "emotion";
import styled from "@emotion/styled";
import { Formik, FormikActions, FormikErrors } from "formik";
import { ErrorText } from "../../styles/Form";

const Input = styled(TextInput)`
  margin-top: 20px;
  > label {
    font-size: 10px;
  }
  > input {
    font-size: 12px;
    height: auto;
  }
`;

interface FormValues {
  email: string;
  temporaryPassword: string;
}

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  if (!values.email) {
    errors.email = "メールアドレスが入力されていません";
  } else if (!values.email.includes("@")) {
    errors.email = "メールアドレスの形式が不正です";
  }

  if (!values.temporaryPassword) {
    errors.temporaryPassword = "仮パスワードが入力されていません";
  }

  return errors;
};

export const RegistrationTemporaryPasswordForm: React.FunctionComponent<{
  validateTemporaryPassword: (values: FormValues) => Promise<void>;
}> = ({ validateTemporaryPassword }) => {
  const handleSubmit = useCallback(
    (values: FormValues, actions: FormikActions<FormValues>) => {
      void validateTemporaryPassword(values).finally(() =>
        actions.setSubmitting(false)
      );
    },
    [validateTemporaryPassword]
  );

  return (
    <Style.Wrapper>
      <Style.Summary>
        <Style.Title>仮パスワードを入力してください</Style.Title>
        <Style.Caption>
          招待されたメールアドレスと招待メールに記載された仮パスワードを入力してください
        </Style.Caption>
      </Style.Summary>
      <Formik<FormValues>
        initialValues={{
          email: "",
          temporaryPassword: ""
        }}
        onSubmit={handleSubmit}
        validate={validate}
        render={({
          values,
          handleSubmit,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          touched,
          isValid,
          dirty
        }) => {
          return (
            <Style.Content>
              <Style.Form onSubmit={handleSubmit}>
                <Input
                  name="email"
                  label="メールアドレス"
                  value={values.email}
                  required
                  formSize="thin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={css`
                    margin-top: 30px;
                    width: 100%;
                  `}
                  status={touched.email && !!errors.email ? "error" : undefined}
                />
                {touched.email && !!errors.email && (
                  <ErrorText>{errors.email}</ErrorText>
                )}
                <Input
                  name="temporaryPassword"
                  type="password"
                  label="仮パスワード"
                  value={values.temporaryPassword}
                  onChange={handleChange}
                  required
                  formSize="thin"
                  onBlur={handleBlur}
                  className={css`
                    width: 100%;
                  `}
                  status={
                    touched.temporaryPassword && !!errors.temporaryPassword
                      ? "error"
                      : undefined
                  }
                />
                {touched.temporaryPassword && !!errors.temporaryPassword && (
                  <ErrorText>{errors.temporaryPassword}</ErrorText>
                )}
                <Style.FormAction>
                  <Button
                    className={css`
                      width: 120px;
                      font-size: 12px;
                      font-weight: normal;
                    `}
                    color="accent"
                    type="submit"
                    disabled={isSubmitting || !dirty || !isValid}
                  >
                    次へ
                  </Button>
                </Style.FormAction>
              </Style.Form>
            </Style.Content>
          );
        }}
      />
    </Style.Wrapper>
  );
};
