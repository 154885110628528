import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, history } from "./store";
import { ConnectedRouter } from "connected-react-router";
import { DefaultThemeProvider } from "chamber";
import NotificationSystem from "react-notification-system";
import { Routes } from "./Routes";
import { Global } from "@emotion/core";
import "semantic-ui-css/semantic.min.css";
import ReactGa from "react-ga";
import { GlobalFont, TextColor } from "chamber/lib/designs";
import { GA_TRACKING_ID } from "./constants";
import { SimpleSnackbar } from "./components/Notification";

ReactGa.initialize(GA_TRACKING_ID, { debug: true });

ReactDOM.render(
  <Provider store={store}>
    <DefaultThemeProvider>
      <>
        <Global
          styles={{
            body: {
              backgroundColor: "#f1f2f5",
              fontFamily: `${GlobalFont}`,
              color: `${TextColor}`
            },
            // semantic.min.cssで上書きされるため。不要になったら外してください
            "button, input, optgroup, select, textarea": {
              fontFamily: `${GlobalFont}`
            },
            "#root": {
              height: "100vh"
            }
          }}
        />
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
        <NotificationSystem />
        <SimpleSnackbar />
      </>
    </DefaultThemeProvider>
  </Provider>,
  document.getElementById("root")
);
