import { FormikErrors } from "formik";
import { FormValues } from "./index";

export const validator = (formValues: FormValues): FormikErrors<FormValues> => {
  const errors: FormikErrors<FormValues> = {};

  if (!formValues.email) {
    errors.email = "メールアドレスが入力されていません";
  } else if (!formValues.email.includes("@")) {
    errors.email = "メールアドレスの形式が不正です";
  }

  return errors;
};
