import { NOTIFICATION } from "./reducers";

export const openNotification = (message: string) =>
  ({
    type: NOTIFICATION.OPEN,
    payload: {
      message,
      open: true
    }
  } as const);

export const closeNotification = () =>
  ({
    type: NOTIFICATION.CLOSE,
    payload: {
      message: undefined,
      open: false
    }
  } as const);
