import { css } from "emotion";
import * as Color from "chamber/lib/designs";
import React from "react";

interface Props {
  children: React.ReactElement;
  renderLink?: () => React.ReactElement;
}

export const SimpleFormBox: React.FunctionComponent<Props> = ({
  children,
  renderLink
}) => (
  <div
    className={css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    `}
  >
    <div>
      <div
        className={css`
          background: ${Color.White};
          border-radius: 8px;
          width: 330px;
          min-height: 330px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
          padding: 30px 50px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <img
          alt="JapanTaxi業務支援"
          src="/JapanTaxi_logo.png"
          className={css`
            width: 166px;
            height: auto;
            margin: 0 auto 10px;
            display: block;
          `}
        />
        {children}
      </div>
      {renderLink && renderLink()}
    </div>
  </div>
);
