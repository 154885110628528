import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store";
import { openNotification, closeNotification } from "./actions";

export const useNotification = () => {
  const notificationState = useSelector(
    (state: StoreState) => state.notification
  );
  const { message, open } = notificationState;
  const dispatch = useDispatch();
  const closeMessage = () => dispatch(closeNotification());

  return {
    message,
    open,
    closeMessage,
    showMessage: (message: string) => dispatch(openNotification(message))
  };
};
