import React from "react";
import { css } from "emotion";
import * as Color from "chamber/lib/designs";

interface props {
  children: React.ReactElement;
  renderLink?: () => React.ReactElement;
}

export const NotLoggedInLayout: React.FunctionComponent<props> = ({
  children
}) => {
  return (
    <>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
      >
        {children}
        <footer
          className={css`
            margin-top: auto;
            padding: 15px 20px;
            background: ${Color.JtxBlue};
            display: flex;
            color: ${Color.White};
            font-size: 12px;
            text-align: right;
          `}
        >
          <small
            className={css`
              font-size: 100%;
              margin-left: auto;
            `}
          >
            <span dangerouslySetInnerHTML={{ __html: "&copy;" }} />
            {`${new Date().getFullYear()} GO Inc., Ltd.`}
          </small>
        </footer>
      </div>
    </>
  );
};
