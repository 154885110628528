import { FormikConfig } from "formik";
import { validator } from "./validator";
import { useLogin } from "../../hooks/useLogin";
import { useDispatch } from "react-redux";
import { openNotification } from "../../modules/notification/actions";

export interface FormValues {
  email: string;
  password: string;
}

export const useLoginForm = () => {
  const { login, redirect } = useLogin();
  const dispatch = useDispatch();
  const formikProps: FormikConfig<FormValues> = {
    onSubmit: async (values, formikActions) => {
      try {
        await login(values.email, values.password);
        redirect();
      } catch {
        dispatch(
          openNotification("メールアドレスとパスワードの組み合わせが不正です")
        );
      } finally {
        formikActions.setSubmitting(false);
      }
    },
    initialValues: {
      email: "",
      password: ""
    },
    validateOnChange: false,
    validate: validator
  };

  return {
    formikProps
  };
};
